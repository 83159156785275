<template>
  <div class="fields-display" :style="grid.container.style">
    <template v-for="item in grid.items">
      <div
        :key="`item_${item.field.id}`"
        :style="item.style"
      >
        <b-button
          :variant="item.field.id === (value && value.id) ? 'primary' : 'white'"
          size="sm"
          class="w-100 rounded-0"
          :class="{ 'invalid-class': !isFieldValid(item.field) }"
          @click="trigger(item.field)"
        >
          <span v-if="item.field.field_name.length > 0">
            {{ item.field.field_name }}
          </span>
          <span v-else>
            New
          </span>
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import {
  validateLabelField,
  sortLabelFields,
} from '@/helpers';

export default {
  name: 'FieldsDisplay',
  components: {},
  props: {
    value: Object,
    fields: Array,
  },
  computed: {
    grid() {
      const fields = sortLabelFields(this.fields);
      // get rows, columns
      const rowColumns = {};
      fields.forEach((field) => {
        const row = field.field_row;
        if (rowColumns[row] === undefined) {
          rowColumns[row] = 0;
        }
        rowColumns[row]++;
      });
      const rows = Object.keys(rowColumns).length;
      const columns = Math.max(...Object.values(rowColumns));

      // build items grid
      const items = [];
      let row = 1;
      let column = 1;
      let lastField = null;
      fields.forEach((field) => {
        if (lastField) {
          column++;
        }
        if (lastField && lastField.field_row !== field.field_row) {
          row++;
          column = 1;
        }
        items.push({
          style: {
            'grid-row': row,
            'grid-column': column,
          },
          field,
        });
        lastField = field;
      });

      const container = {
        style: {
          'grid-template-rows': `repeat(${rows}, 1fr)`,
          'grid-template-columns': `repeat(${columns}, 1fr)`,
        },
      };

      return {
        container,
        items,
      };
    },
  },
  data() {
    return {};
  },
  methods: {
    trigger(field) {
      const emitValue = this.value !== null && field.id === this.value.id ? null : field;
      this.$emit('input', emitValue);
    },
    isFieldValid(field) {
      return validateLabelField(field);
    },
  },
};
</script>

<style lang="scss" scoped>
  .fields-display {
    display: grid;
    gap: 2px;
  }
</style>
